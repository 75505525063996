var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo flex", attrs: { "b-link": "", to: "/" } },
        [
          _c("span", { staticClass: "logo-img" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-title" }, [
            _vm._v("Explore"),
            _c("br"),
            _vm._v("Mandelbrot"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { "is-nav": "", id: "header-tabs" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }