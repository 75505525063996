import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const theme = {
  primary: '#558b2f',
  secondary: 'ffa000',
  accent: '#512da8',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FFC107',
};

const opts = {};

const VuetifyObj = new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: true,
  },
});

export default VuetifyObj;
