import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Config = () => import('@/entities/config/config.vue');
// prettier-ignore
const ConfigUpdate = () => import('@/entities/config/config-update.vue');
// prettier-ignore
const ConfigDetails = () => import('@/entities/config/config-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'config',
      name: 'Config',
      component: Config,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/new',
      name: 'ConfigCreate',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/edit',
      name: 'ConfigEdit',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/view',
      name: 'ConfigView',
      component: ConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
