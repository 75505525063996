var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        _c("ribbon"),
        _vm._v(" "),
        _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container-fluid pa-4" },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }